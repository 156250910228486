import axiosClient from 'utils/axiosClient';
import api from 'api';

const getTickets = async (params?: any) => {
	const response = await axiosClient.get(api.TICKETS.TICKETS, {params});
	return response.data;
};
const getUsersSoldTickets = async (params?: any) => {
	const response = await axiosClient.get(api.TICKETS.USERS_SOLD_TICKETS, {params});
	return response.data;
};
const getTicketById = async (id: number) => {
	const response = await axiosClient.get(api.TICKETS.TICKETS + '/' + id);
	return response.data;
};
const createTicket = async (data: any) => {
	const response = await axiosClient.post(api.TICKETS.TICKETS, data);
	return response.data;
};

const deleteTicket = async (data: any) => {
	const response = await axiosClient.delete(api.TICKETS.TICKETS, {data});
	return response.data;
};

const updateTicket = async (data: any, id: any) => {
	const response = await axiosClient.patch(api.TICKETS.TICKETS + '/' + id, data);
	return response.data;
};
const attachRouteToTicket = async (data: any, id: any) => {
	const response = await axiosClient.post(api.TICKETS.TICKETS + '/' + id + '/routes', data);
	return response.data;
};

const deattachRouteToTicket = async (data: any, id: any) => {
	const response = await axiosClient.delete(api.TICKETS.TICKETS + '/' + id + '/routes', {data});
	return response.data;
};

const service = {
	getTickets,
	getTicketById,
	createTicket,
	deleteTicket,
	updateTicket,
	attachRouteToTicket,
	deattachRouteToTicket,
	getUsersSoldTickets
};

export default service;
