import axiosClient from 'utils/axiosClient';
import api from 'api';

interface LoginTypes {
	email: string;
	password: string;
}

interface RegTypes {}

const login = async (data: LoginTypes) => {
	const response = await axiosClient.post(api.AUTH.LOGIN, data);
	return response.data;
};

const logout = async () => {
	const response = await axiosClient.post(api.AUTH.LOGOUT);
	return response.data;
};

const register = async (data: RegTypes) => {
	const response = await axiosClient.post(api.AUTH.REGISTER, data);
	return response.data;
};

const confirm = async (token: string) => {
	const response = await axiosClient.post(api.AUTH.CONFIRM, token);
	return response.data;
};

const resetPassword = async (token: string, data: {password: string; c_password: string}) => {
	const response = await axiosClient.post(api.AUTH.RESET_PASSWORD + token, data);
	return response.data;
};

const service = {
	login,
	logout,
	register,
	confirm,
	resetPassword
};

export default service;
