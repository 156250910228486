import axiosClient from 'utils/axiosClient';
import api from 'api';

const syncValidators = async () => {
	const response = await axiosClient.post(api.VALIDATORS.VALIDATORS + '/sync');
	return response.data;
};

const syncValidatorLogs = async () => {
	const response = await axiosClient.post(api.VALIDATORS.VALIDATOR_LOGS + '/sync');
	return response.data;
};

const getValidators = async (params?: any) => {
	const response = await axiosClient.get(api.VALIDATORS.VALIDATORS, {params});
	return response.data;
};
const getValidatorLogs = async (params?: any) => {
	const response = await axiosClient.get(api.VALIDATORS.VALIDATOR_LOGS, {params});
	return response.data;
};

const getValidatorById = async (id: any) => {
	const response = await axiosClient.get(api.VALIDATORS.VALIDATORS + '/' + id);
	return response.data;
};
const getValidatorLogsById = async (id?: any) => {
	const response = await axiosClient.get(api.VALIDATORS.VALIDATOR_LOGS + '/' + id);
	return response.data;
};

const deleteValidator = async (data: any) => {
	const response = await axiosClient.delete(api.VALIDATORS.VALIDATORS, {data});
	return response.data;
};

const updateValidator = async (data: any, id: any) => {
	const response = await axiosClient.patch(api.VALIDATORS.VALIDATORS + '/' + id, data);
	return response.data;
};

const service = {
	getValidators,
	getValidatorById,
	syncValidators,
	deleteValidator,
	updateValidator,
	getValidatorLogs,
	getValidatorLogsById,
	syncValidatorLogs
};

export default service;
