enum API {
	LOGIN = '/v1/admins/auth/sign-in',
	LOGOUT = '/v1/auth/sign-out',

	REGISTER = '/auth/register',
	CONFIRM = '/auth/confirm',
	RESET_PASSWORD = '/auth/resetPassword/'
}

export default API;
