import {ConfigProvider, Layout} from 'antd';
import 'antd/dist/antd.less';
import 'antd/dist/antd.variable.min.css';
import en from 'antd/es/locale/en_GB';
import fr from 'antd/lib/locale/fr_FR';
import hy from 'antd/lib/locale/hy_AM';
import ru from 'antd/lib/locale/ru_RU';
import FullScreenLoading from 'components/FullScreenLoading';
import Footer from 'components/layout/Footer';
import Header from 'components/layout/Header';
import SiderMenu from 'components/layout/Sider/Sider';
import {AuthContext} from 'context/Auth.context';
import Login from 'pages/Login';
import ResetPassword from 'pages/ResetPassword/ResetPassword';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Route, Routes} from 'react-router-dom';
import {RouteProps, routes} from 'router';
import {LoginRoute, PrivateRoute} from 'routes/wrappers';
import './App.less';
import SERVICES from 'services';
import {SettingsContext} from 'context/Settings.context';

const langs: any = {
	hy,
	fr,
	en,
	ru
};
export const paths: Record<string, {path: string; pagePath: string; id: number; disabled?: boolean}> = {
	1: {path: '/', pagePath: '/Dashboard', id: 1},
	3: {path: '/users/list', pagePath: '/Users/Main', id: 3},
	4: {path: '/users/privileges', pagePath: '/Users/Privileges', id: 4},
	5: {path: '/users/payments', pagePath: '/Payments/Main', id: 5},
	6: {path: '/users/complaints', pagePath: '/Users/Complaints', id: 6},
	8: {path: '/partners/list', pagePath: '/Partners/Main', id: 8},
	9: {path: '/partners/drivers', pagePath: '/Partners/Drivers', id: 9},
	10: {path: '/partners/buses', pagePath: '/Partners/Buses', id: 10},
	12: {path: '/partners/transferList', pagePath: '/Partners/Transfers', id: 12},
	14: {path: '/schedules/marches', pagePath: '/Schedules/Marches', id: 14},
	15: {path: '/schedules/stops', pagePath: '/Schedules/Stops', id: 15},
	17: {path: '/tickets/types', pagePath: '/Tickets/Types', id: 17},
	18: {path: '/tickets/sold', pagePath: '/Tickets/Sold', id: 18},
	19: {path: '/carriers', pagePath: '/Carriers/Warehouse', id: 19},
	24: {path: '/validators/list', pagePath: '/ValidatorList/Main', id: 24},
	25: {path: '/validators/history', pagePath: '/ValidatorList/History', id: 25},
	26: {path: '/validators/logs', pagePath: '/ValidatorList/ValidatorLogs', id: 26},

	21: {path: '/transactions', pagePath: '/Transactions/Main', id: 21},
	27: {path: '/history', pagePath: '/History', id: 27},

	30: {path: '/settings', pagePath: '/Settings', id: 30}
};

const {Content} = Layout;

export interface RoutesObjectType {
	path: string;
	page: string;
	pageId: string;
	pagePath: string;
	component?: any;
	disabled?: boolean;
}

function App() {
	const {i18n} = useTranslation();
	const {state: authState} = useContext(AuthContext);
	const {t} = useTranslation();
	const {state: settingsState, dispatch: settingsDispatch} = useContext(SettingsContext);

	const getRoutesObjects = (routes: RouteProps[]) => {
		return routes.reduce((acc: RoutesObjectType[], route) => {
			if (route.children && route.children.length) {
				acc = acc.concat(getRoutesObjects(route.children));
			} else {
				acc.push({
					path: paths[route.id]?.path ?? '',
					pageId: route.id.toString(),
					page: route.label,
					pagePath: paths[route.id]?.pagePath ?? '/Dashboard',
					disabled: route.disabled
				});
				acc.push({
					path: '/settings',
					pageId: '27',
					page: 'Settings',
					pagePath: '/Settings'
				});
			}

			return acc;
		}, []);
	};

	const availableRoutesList = useMemo(() => {
		return getRoutesObjects(settingsState.menuItems);
	}, [settingsState.menuItems]);

	const [loading, setLoading] = useState(false);
	useEffect(() => {
		// if (languagesLoading) return;
		if (authState.isAuthenticated) {
			if (!settingsState.menuItems.length) setLoading(true);
			SERVICES.SETTINGS.getMenu()
				.then(res => {
					settingsDispatch({
						type: 'GET_MENU',
						payload: res.data
					});
				})
				.finally(() => {
					setLoading(false);
				});

			// SERVICES.SETTINGS.getDictionary('curriculumAcademicYears').then(res => {
			// 	const currentYear = new Date().getFullYear();
			// 	const currentMonth = new Date().getMonth() + 1;

			// 	const option = res.data.find((el: any) => {
			// 		if (currentMonth >= 8) {
			// 			return +el.name.substr(0, el.name.indexOf('-')) === currentYear;
			// 		} else {
			// 			return +el.name.substr(el.name.indexOf('-') + 1) === currentYear;
			// 		}
			// 	});

			// 	if (option) {
			// 		settingsDispatch({
			// 			type: 'GET_CURRENT_ACADEMIC_YEAR_ID',
			// 			payload: option.id
			// 		});
			// 	}
			// });
		}
	}, [authState.isAuthenticated, i18n.language]);

	const isRouteAvailable = (routePageId: string) => {
		return !!availableRoutesList.filter(route => route.pageId === routePageId).length;
	};

	if (!i18n.isInitialized || loading) return <FullScreenLoading size="large" />;

	return (
		<ConfigProvider locale={langs[i18n.language] || en}>
			<Layout>
				{authState.isAuthenticated && <SiderMenu />}
				<Layout className="layout">
					{authState.isAuthenticated !== undefined ? (
						<>
							{authState.isAuthenticated && <Header />}
							<Content className={`content ${authState.isAuthenticated && 'bordered-content'}`}>
								<Routes>
									{availableRoutesList.map(({path, pageId, pagePath, disabled}) => {
										let Component = React.lazy(() => import(`pages${pagePath}`));

										if (isRouteAvailable(pageId)) {
											return (
												<Route
													path={path === '/main' ? '/' : path}
													key={pageId}
													element={
														<PrivateRoute
															isAuthenticated={!!authState.isAuthenticated}>
															<Component
															// permissions={{
															// 	post,
															// 	patch,
															// 	remove
															// }}
															/>
														</PrivateRoute>
													}
												/>
											);
										}
									})}
									<Route
										path="/"
										element={
											<PrivateRoute isAuthenticated={!!authState.isAuthenticated}>
												<></>
											</PrivateRoute>
										}
									/>

									<Route
										path="/login"
										element={
											<LoginRoute isAuthenticated={!!authState.isAuthenticated}>
												<Login />
											</LoginRoute>
										}
									/>
									<Route
										path="/reset_password"
										element={
											<LoginRoute isAuthenticated={!!authState.isAuthenticated}>
												<ResetPassword />
											</LoginRoute>
										}
									/>
									<Route
										path="*"
										element={
											<PrivateRoute isAuthenticated={!!authState.isAuthenticated}>
												<div>NOT FOUND</div>
											</PrivateRoute>
										}
									/>
								</Routes>
							</Content>
							{!!authState.isAuthenticated && <Footer />}
						</>
					) : null}
				</Layout>
			</Layout>
		</ConfigProvider>
	);
}

export default App;
