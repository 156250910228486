import axiosClient from 'utils/axiosClient';
import api from 'api';

const getNotifications = async () => {
	const response = await axiosClient.get(api.NOTIFICATIONS.GET_NOTIFICATIONS);
	return response.data;
};

const getNotificationsAccess = async (params?: any) => {
	const response = await axiosClient.get(api.NOTIFICATIONS.NOTIFICATION_ACCESS, {params});
	return response.data;
};

const createNotificationAccess = async (data: any) => {
	const response = await axiosClient.post(api.NOTIFICATIONS.NOTIFICATION_ACCESS, data);
	return response.data;
};

const deleteNotificationAccess = async (ids: number[]) => {
	const response = await axiosClient.delete(api.NOTIFICATIONS.NOTIFICATION_ACCESS + '/' + ids);
	return response.data;
};

const service = {
	getNotifications,
	getNotificationsAccess,
	createNotificationAccess,
	deleteNotificationAccess
};

export default service;
