import {Navigate, useLocation} from 'react-router-dom';

export function LoginRoute({isAuthenticated, children}: {isAuthenticated: boolean; children: JSX.Element}) {
	let location = useLocation();

	if (isAuthenticated) {
		return <Navigate to="/" state={{from: location}} replace />;
	}

	return children;
}

export function PrivateRoute({isAuthenticated, children}: {isAuthenticated: boolean; children: any}) {
	let location = useLocation();

	if (!isAuthenticated) {
		return <Navigate to="/login" state={{from: location}} replace />;
	}

	return children;
}
