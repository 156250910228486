import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getTransfers = async (params: any) => {
	const response = await axiosClient.get(api.TRANSFERS.TRANSFERS, {params});
	return response.data;
};

const getTransferById = async (id: any) => {
	const response = await axiosClient.get(api.TRANSFERS.TRANSFERS + '/' + id);
	return response.data;
};

const createTransfer = async (data: any) => {
	const response = await axiosClient.post(api.TRANSFERS.TRANSFERS, formData(data));
	return response.data;
};

const deleteTransfer = async (data: any) => {
	const response = await axiosClient.delete(api.TRANSFERS.TRANSFERS, {data});
	return response.data;
};

const updateTransfer = async (data: any, id: any) => {
	const response = await axiosClient.patch(api.TRANSFERS.TRANSFERS + '/' + id, formData(data));
	return response.data;
};

const service = {
	getTransfers,
	getTransferById,
	createTransfer,
	deleteTransfer,
	updateTransfer
};

export default service;
