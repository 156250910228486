import axiosClient from 'utils/axiosClient';
import api from 'api';

const getAdmins = async (params?: any) => {
	const response = await axiosClient.get(api.ADMINS.ADMINS, {params});
	return response.data;
};

const getAdminById = async (id: any) => {
	const response = await axiosClient.get(api.ADMINS.ADMINS + '/' + id);
	return response.data;
};

const createAdmin = async (data: any) => {
	const response = await axiosClient.post(api.ADMINS.ADMINS, data);
	return response.data;
};

const deleteAdmin = async (data: any) => {
	const response = await axiosClient.delete(api.ADMINS.ADMINS, {data});
	return response.data;
};

const updateAdmin = async (data: any, id: any) => {
	const response = await axiosClient.patch(api.ADMINS.ADMINS + '/' + id, data);
	return response.data;
};

const service = {
	getAdmins,
	getAdminById,
	createAdmin,
	deleteAdmin,
	updateAdmin
};

export default service;
