import AUTH from './auth.api';
import SETTINGS from './settings.api';
import USERS from './users.api';
import EXCEL from './excel.api';
import NOTIFICATIONS from './notifications.api';
import PARTNERS from './partners.api';
import DRIVERS from './partners.drivers.api';
import PERMISSIONS from './permissions.api';
import ROUTES from './routes.api';
import CARRIERS from './carriers.api';
import TICKETS from './tickets.api';
import FAQ from './faq.api';
import NEWS from './news.api';
import BANNERS from './banners.api';
import TRANSACTIONS from './transactions.api';
import VALIDATORS from './validators.api';
import COMPLAINTS from './complaints.api';
import BUSES from './buses.api';
import BUSSTOPS from './busStops.api';
import CONTACTS from './contacts.api';
import TRANSFERS from './transfers.api';
import ADMINS from './admins.api';

const APP_API = {
	AUTH,
	SETTINGS,
	USERS,
	EXCEL,
	NOTIFICATIONS,
	PARTNERS,
	DRIVERS,
	PERMISSIONS,
	ROUTES,
	CARRIERS,
	TICKETS,
	FAQ,
	NEWS,
	BANNERS,
	TRANSACTIONS,
	VALIDATORS,
	COMPLAINTS,
	BUSES,
	BUSSTOPS,
	CONTACTS,
	TRANSFERS,
	ADMINS
};

export default APP_API;
