import {Dispatch, ReactNode, createContext, useContext, useEffect, useReducer} from 'react';
import packageJSON from '../../package.json';
import {AuthContext} from './Auth.context';

export interface SettingsStateProps {
	menuItems: MenuItem[] | any;
}

interface InitContextProps {
	state: SettingsStateProps;
	dispatch: Dispatch<Action>;
}

export interface MenuItem {
	creationDate: string;
	delete: 0 | 1;
	get: 0 | 1;
	id: number;
	itemOrderNum: number;
	itemParentID: number;
	lastModifiedDate: string | null;
	page: string;
	pageId: number;
	post: 0 | 1;
	privilegieId: number;
	privilegies_name: string;
	patch: 0 | 1;
	children: MenuItem[] | [];
	url: string;
}

type Action = {type: 'GET_MENU'; payload: MenuItem[]};

export const SettingsContext = createContext({} as InitContextProps);

const reducer = (state: SettingsStateProps, action: Action) => {
	switch (action.type) {
		case 'GET_MENU':
			return {
				...state,
				menuItems: action.payload
			};
		default:
			return state;
	}
};
const initialState = {
	menuItems: []
};
const SettingsProvider = ({children}: {children: ReactNode}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const value = {state, dispatch};
	const {state: authState} = useContext(AuthContext);

	useEffect(() => {
		const version = localStorage.getItem('version');
		if (packageJSON.needToCleanLocaleStorage && version !== packageJSON.version) {
			const currentLang = localStorage.getItem('language');
			localStorage.clear();
			localStorage.setItem('language', currentLang || 'hy');
		}
		localStorage.setItem('version', packageJSON.version);
	}, [authState.isAuthenticated]);
	return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};

export default SettingsProvider;
